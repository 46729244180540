import React from 'react';
import logo from "../logo.png"
import { TextField, Button, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import { NotificationManager } from "react-notifications"
import { user } from "../api"
const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 220,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        height: "100vh"
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
    },
    logo: {
        width: 220,
        objectFit: "contain"
    },
    button: {
        width: 220,
        color: "white"
    },
}));

const Login = (props) => {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        email: '',
        password: ''
    });

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const onSubmit = () => {
        const { email, password } = values
        user.login({ email, password })
            .then(async res => {
                await localStorage.setItem("token", res.data.token)
                props.history.push("/home");
            })
            .catch(err => NotificationManager.error('Email password combination incorrect!'))
    }

    return (
        <div className={classes.container}>
            <img src={logo} className={classes.logo}></img>
            <form className={classes.formContainer} onSubmit={onSubmit}>
                <TextField
                    className={classes.textField}
                    fullWidth
                    id="email"
                    label="Email"
                    placeholder="email@example.com"
                    margin="normal"
                    value={values.email}
                    onChange={handleChange("email")}
                />
                <TextField
                    className={classes.textField}
                    fullWidth
                    id="password"
                    label=""
                    placeholder="********"
                    margin="normal"
                    type="password"
                    value={values.password}
                    onChange={handleChange("password")}
                />
                <Typography variant="body2" className="mt-3 mr-2 align-self-end">
                    <Link to="/forgot_password">Forgot your password?</Link>
                </Typography>
            </form>
            <div>
                <Button onClick={onSubmit} variant="contained" color="primary" className={classes.button}>
                    Log In
                </Button>
                <Typography variant="subtitle2" className="mt-3">
                    <Link to="/register">Don't have an account? Sign up.</Link>
                </Typography>
            </div>
        </div>
    );
};

export default Login;