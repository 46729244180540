import axios from "axios";
import { BASE_URL } from "./config"

const defaultHeaders = () => ({
    Authorization: "Bearer " + localStorage.getItem("token")
});

export const get = (url, params = {}, headers = defaultHeaders()) =>
    axios
        .get(BASE_URL + url, { params, headers })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            throw error;
        });

export const post = (url, body = {}, headers = defaultHeaders()) =>
    axios
        .post(BASE_URL + url, body, { headers })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            throw error;
        });

export const put = (url, body = {}, headers = defaultHeaders()) =>
    axios
        .put(BASE_URL + url, body, { headers })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            throw error;
        });

export const patch = (url, body = {}, headers = defaultHeaders()) =>
    axios
        .patch(BASE_URL + url, body, { headers })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            throw error;
        });

export const del = (url, body = {}, headers = defaultHeaders()) =>
    axios
        .delete(BASE_URL + url, { body, headers })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            throw error;
        });

export const user = {
    login: body => post("user/login", body),
    register: body => post("user/register", body),
    reset_password: body => post("user/reset_password", body),
    send_reset_password: body => post("user/send_reset_password", body),
};

export const lock = {
    list: () => get("lock"),
    unlock: (id) => post(`lock/${id}/unlock`, {}),
    lock: (id) => post(`lock/${id}/lock`, {}),
    get_status: (id) => post(`lock/${id}/get_status`, {}),
};