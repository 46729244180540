
import React, { useState } from 'react';
import { IconButton, Icon, TextField, Button, Typography, CircularProgress } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { makeStyles } from '@material-ui/core/styles';
import { user } from '../api'

const useStyles = makeStyles(theme => ({
    container: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1),
        height: 50,
        width: "100%",
        boxShadow: "0px 0px 5px 0px rgba(128,128,128,1);"
    },
    body: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: 500,
        width: "100%"
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        width: "100%",
        color: "white"
    },
    info: {
        backgroundColor: "#d4edda",
        color: "#155724",
        padding: theme.spacing.unit * 2,
        borderRadius: 5
    },
    error: {
        color: "red"
    },
    loader: {
        display: "flex",
        padding: theme.spacing.unit * 3,
        justifyContent: "center",
        alignItems: "center"
    }
}));

const ForgotPassword = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [code, setCode] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const goBack = () => {
        props.history.push("/login");
    }

    const send_code = (e) => {
        e && e.preventDefault();
        setLoading(true);
        user.send_reset_password({ email })
            .then(async res => {
                NotificationManager.success('Code sent successfully');
                setIsCodeSent(true)
                setLoading(false);
            })
            .catch(err => {
                if (err && err.response && err.response.data && err.response.data.message) {
                    NotificationManager.error(err.response.data.message)
                } else {
                    NotificationManager.error('Server error')
                }
                setLoading(false)
            })
    }

    const reset_password = (e) => {
        e && e.preventDefault();
        setLoading(true);
        user.reset_password({ code, password })
            .then(async res => {
                NotificationManager.success('Password reset successfully');
                props.history.push("/login");
            })
            .catch(err => {
                if (err && err.response && err.response.data && err.response.data.message) {
                    NotificationManager.error(err.response.data.message)
                } else {
                    NotificationManager.error('Server error')
                }
                setLoading(false)
            })
    }

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <IconButton aria-label="Log Out" onClick={goBack}>
                    <Icon>arrow_back</Icon>
                </IconButton>
            </div>
            {
                loading ? <div className={classes.loader}><CircularProgress size={20} /></div> :
                    !isCodeSent
                        ? <form className={`${classes.body} p-3`} onSubmit={send_code}>
                            <Typography variant="caption" className="align-self-start">
                                {"What is your email?"}
                            </Typography>
                            <TextField
                                className={classes.textField}
                                fullWidth
                                id="email"
                                label="Email"
                                required
                                placeholder="email@example.com"
                                margin="normal"
                                inputProps={{ type: "email" }}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                {"Send code"}
                            </Button>
                        </form>
                        : <form className={`${classes.body} p-3`} onSubmit={reset_password}>
                            <Typography className={classes.info}>
                                You will receive an email with 6 digit security code shortly
                        </Typography>
                            <TextField
                                className={classes.textField}
                                fullWidth
                                id="code"
                                label="Code"
                                placeholder="123456"
                                inputProps={{ maxLength: 6, minLength: 6 }}
                                margin="normal"
                                value={code}
                                onChange={e => setCode(e.target.value)}
                            />
                            <TextField
                                className={classes.textField}
                                fullWidth
                                id="password"
                                label="New password"
                                placeholder="********"
                                type="password"
                                margin="normal"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                            <TextField
                                className={classes.textField}
                                fullWidth
                                id="repeatPassword"
                                label="Repeat password"
                                placeholder="********"
                                type="password"
                                margin="normal"
                                value={repeatPassword}
                                onChange={e => setRepeatPassword(e.target.value)}
                            />
                            {
                                password != repeatPassword && <Typography className={classes.error}>Passwords do not match</Typography>
                            }
                            <Button disabled={password != repeatPassword || password.length < 6} type="submit" variant="contained" color="primary" className={classes.button}>
                                {"Reset password"}
                            </Button>
                        </form>
            }

        </div>
    );
};

export default ForgotPassword;