import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import "react-notifications/lib/notifications.css";
import "typeface-roboto";
import 'bootstrap/dist/css/bootstrap.css';

import Login from "./Login/Login";
import Register from "./Register/Register"
import ForgotPassword from "./ForgotPassword/ForgotPassword"
import LoggedIn from "./LoggedIn/LoggedIn"
import PublicRoute from "./Routes/PublicRoute";
import PrivateRoute from "./Routes/PrivateRoute";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#c4a47d"
    }
  }
})

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <NotificationContainer />
          <Switch>
            <Redirect exact path="/" to="/login" />
            <PublicRoute exact path="/login" component={Login} />
            <PublicRoute exact path="/register" component={Register} />
            <PublicRoute exact path="/forgot_password" component={ForgotPassword} />
            <PrivateRoute exact path="/home" component={LoggedIn} />
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}

export default App;
