import React, { useState, useEffect, Fragment } from 'react';
import { lock } from '../api';
import { makeStyles } from '@material-ui/core/styles';
import { NotificationManager } from "react-notifications";
import { FormControl, InputLabel, Select, Button, MenuItem, Icon } from "@material-ui/core"

import Slider from "./Slider";

const useStyles = makeStyles(theme => ({
    container: {
        height: "calc(100vh - 50px)",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    selectLock: {
        marginTop: theme.spacing(2),
        width: 220
    },
    lockName: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: theme.spacing(2)
    },
    bigcircle: {
        width: 260,
        height: 260,
        borderRadius: 125,
        border: "1px solid #ececec",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(2)
    },
    smallcircle: {
        width: 200,
        height: 200,
        borderRadius: 125,
        border: "1px solid #ececec",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#ae8453",
        fontSize: 110
    },
    button: {
        width: 220,
        marginTop: theme.spacing(2),
        color: "white"
    }
}));

const Home = () => {
    const [locks, setLocks] = useState([]);
    const [selectedLock, setSelectedLock] = useState(null);
    const [lockState, setLockState] = useState("locked");
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        lock.list()
            .then(res => {
                setLocks(res.data)
            })
            .catch(err => {
                NotificationManager.error('Network error, please check connection');
            })
    }, [])

    const handleLockChange = e => {
        const selected_id = e.target.value;
        const selected_lock = locks.find(lock => lock._id == selected_id);
        setSelectedLock(selected_lock);
    }

    const classes = useStyles();

    const send_unlock_command = (_id) => {
        console.log({ _id })
        lock.unlock(_id)
            .then(res => {
                setLockState("unlocked")
            })
            .catch(err => {
                NotificationManager.error('Error while sending command')
            })
    }

    const send_lock_command = (_id) => {
        lock.lock(_id)
            .then(res => {
                setLockState("locked")
            })
            .catch(err => {
                NotificationManager.error('Error while sending command')
            })
    }

    const send_get_status_command = (_id) => {
        lock.get_status(_id)
            .then(res => {
                console.log({ res })
            })
            .catch(err => {
                NotificationManager.error('Error while sending command')
            })
    }


    const sendCommand = () => {
        const _id = selectedLock._id;
        setDisabled(true)
        setTimeout(() => {
            setDisabled(false)
        }, 5000);
        if (lockState == "locked") {
            return send_unlock_command(_id);
        } else {
            return send_lock_command(_id);
        }
    }

    return (
        <div className={classes.container}>
            <FormControl className={classes.selectLock}>
                <Select
                    value={selectedLock ? selectedLock._id : "select"}
                    onChange={handleLockChange}
                    inputProps={{
                        name: 'lock',
                        id: 'lock',
                    }}
                >
                    <MenuItem value="select" disabled hidden>Please Select Lock</MenuItem>
                    {locks.map(lock => {
                        return <MenuItem key={lock._id} value={lock._id} >{lock.location.name + " - " + lock.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
            {
                selectedLock && <Fragment>
                    <div className={classes.lockName}>
                        <h5>{selectedLock.name}</h5>
                        <p className="mb-0"><Icon fontSize="inherit">location_on</Icon>{selectedLock.location && selectedLock.location.name}</p>
                    </div>
                    <div className={classes.bigcircle}>
                        <div className={classes.smallcircle}>
                            <Icon fontSize="inherit" color="inherit">{lockState == "locked" ? "lock_outline" : "lock_open_outline"}</Icon>
                        </div>
                    </div>
                    
                    <Button disabled={disabled} onClick={sendCommand} variant="contained" style={disabled ? {} : { backgroundColor: lockState == "locked" ? "#00ce00" : "#ce0000" }} className={classes.button}>
                        {lockState == "locked" ? "Unlock" : "Lock"}
                    </Button>
                </Fragment>
            }
        </div>
    );
};

export default Home;
