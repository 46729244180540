import React, { Component } from 'react';

import { makeStyles } from "@material-ui/core";
import "./slider.css"

const green = [75, 181, 67];
const red = [254, 0, 26];
const gray = [198, 198, 198];

const useStyles = makeStyles(theme => ({
    container: {
        WebkitAppearance: "none",
        WebkitBorderRadius: 30,
        borderRadius: 30,
        backgroundColor: arrayToRGB(gray),
        overflow: "hidden",
        width: "80%",
        height: 50,
        WebkitUserSelect: "none",
    },
    sliderButton: {
        width: 40,
        height: 40,
        borderRadius: 40,
        backgroundColor: "#fff"
    }
}))

const arrayToRGB = (colorArray) => {
    return `rgb(${colorArray[0]}, ${colorArray[1]}, ${colorArray[2]})`
}

const Slider = () => {
    const classes = useStyles();

    return (
        <div className="slide-wrap center">
            <label data-text="Slide to unlock">slide to unlock</label>
            <input type="range" min="0" max="100" value="0" />
        </div>
    );
}


export default Slider;