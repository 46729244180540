import React from 'react';
import logo from "../logo.png"
import { TextField, IconButton, Icon } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

import Home from "../Home/Home"

const useStyles = makeStyles(theme => ({
    container: {
        width: "100vw",
        height: "100vh"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1),
        height: 50,
        boxShadow: "0px 0px 5px 0px rgba(128,128,128,1);"
    },
    logo: {
        height: 35,
        // objectFit: 
    },
    content: {

    }

}));

const LoggedIn = () => {
    const classes = useStyles();

    const logout = () => {
        localStorage.removeItem("token");
        window.location.reload();
    }

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <img src={logo} className={classes.logo} />
                <IconButton aria-label="Log Out" onClick={logout}>
                    <Icon>exit_to_app</Icon>
                </IconButton>
            </div>
            <div className={classes.content}>
                <Home />
            </div>
        </div>
    );
};

export default LoggedIn;